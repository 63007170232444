import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { BehaviorSubject, Observable } from 'rxjs';
import { ICommune } from 'src/app/admin/commune/i-commune';
import { Pagination } from 'src/app/core/interfaces/paginations/pagination';
import { environment } from 'src/environments/environment';
import { IshippmentsClient } from '../../interfaces/ishippments-client';

@Injectable({
  providedIn: 'root'
})
export class ShippmentsClientService {

  observAddShipments = new BehaviorSubject<number>(0);
  observedIndexShipmnet = new BehaviorSubject<number>(0);
  constructor(
    private http: HttpClient
  ) { }


  async findAllWilaya(): Promise<any> {
    return await this.http.get<any>(`${environment.apiV1}wilayas`).toPromise();
  }


  deleteshipment(tracking: string) {
    return this.http.delete(`${environment.apiV1}shipments/tracking/${tracking}`);
  }


  getPaginateColisTracabilite() {
    return this.http.get<Pagination<any>>(
      `${environment.apiV1}shipments/paginateColisTracabiliteOfClient`
    );
  }

  funcPaginateColisOfUser(
    link?: string,
    page?: number,
    search?: string
  ): Observable<Pagination<any>> {
    if (page && search == undefined) {
      link = `${environment.apiV1}shipments/paginateColisTracabiliteOfClient?page=${page}`;
      return this.http.get<Pagination<any>>(link)
    }
    if (page && search) {
      link = `${environment.apiV1}shipments/paginateColisTracabiliteOfClient?searchColisTerm=${search}&page=${page}`;
      return this.http.get<Pagination<any>>(link)
    }
    if (search) {
      return this.http.get<Pagination<any>>(`${link}&searchColisTerm=${search}`);
    }
    return this.http.get<Pagination<any>>(link);
  }

  searchColisOfUser(searchColis) {
    return this.http.get<Pagination<any>>(
      `${environment.apiV1}shipments/paginateColisTracabiliteOfClient?searchColisTerm=${searchColis}`
    );
  }
  //////////////////
  getPaginatedShipmentsEnpreparation() {
    return this.http.get<Pagination<any>>(
      `${environment.apiV1}shipments/getPaginatedShipmentsEnpreparation`
    );
  }

  funcPaginateColisOfUserEnprepartion(
    link?: string,
    page?: number,
    search?: string
  ): Observable<Pagination<any>> {
    if (page && search == undefined) {
      link = `${environment.apiV1}shipments/getPaginatedShipmentsEnpreparation?page=${page}`;
      return this.http.get<Pagination<any>>(link)
    }
    if (page && search) {
      link = `${environment.apiV1}shipments/getPaginatedShipmentsEnpreparation?searchColisTerm=${search}&page=${page}`;
      return this.http.get<Pagination<any>>(link)
    }
    if (search) {
      return this.http.get<Pagination<any>>(`${link}&searchColisTerm=${search}`);
    }
    return this.http.get<Pagination<any>>(link);
  }

  searchColisOfUserEnPreparation(searchColis) {
    return this.http.get<Pagination<any>>(
      `${environment.apiV1}shipments/getPaginatedShipmentsEnpreparation?searchColisTerm=${searchColis}`
    );
  }
  ////////////

  getStatistiqueClient() {
    return this.http.get<any>(`${environment.apiV1}shipments/getStatistiqueClient`).toPromise();
  }


  createShipmentByFile(service, shipments) {
    return this.http.post<any>(`${environment.apiV1}shipments/createShipmentByFile`, { service, shipments });
  }

  octallllllllllllllllllllllll


  getAllWilayasYalidine() {
    return this.http.get<any>(`${environment.apiV1}wilayas/getAllWilayasYalidine`)
  }



  createShipmentYalidine(shipment) {
    return this.http.post(`${environment.apiV1}shipments/yalidine`, shipment, { responseType: 'blob' });
  }


  createShipmentOctal(shipment) {
    return this.http.post(`${environment.apiV1}shipments/octal`, shipment, { responseType: 'blob' });
  }



  getAgencesAndCommunesByWilayaIdYalidine(idWilaya) {
    return this.http.get<any>(`${environment.apiV1}agences/getAgencesAndCommunesByWilayaIdYalidine/${idWilaya}`)
  }

  getTarifsLivraison(
    wilayaDepartId: Number, wilayaDestinationId: Number, weight: Number, length: Number, height: Number, width: Number,
    serviceId: Number, is_stopdesk: Boolean, entreprise: string
  ) {
    console.log("🚀 ~ ShippmentsClientService ~ is_stopdesk:", is_stopdesk)
    return this.http.get<any>(`${environment.apiV1}shipments/calculTarifLivraison?wilayaDepartId=${wilayaDepartId}&wilayaDestinationId=${wilayaDestinationId}&weight=${weight}&length=${length}&height=${height}&width=${width}&serviceId=${serviceId}&is_stopdesk=${is_stopdesk}&entreprise=${entreprise}`,)
  }

  printBordereauOctal(tracking: string) {
    return this.http.get(`${environment.apiV1}shipments/printBordereauOctal/${tracking}`, {
      responseType: 'blob',
    })
  }



  createShipmentNoest(shipment) {
    return this.http.post(`${environment.apiV1}shipments/noest`, shipment, { responseType: 'blob' });
  }

  printMultipleBordereauOctal(trackings: string[]) {
    return this.http.get(`${environment.apiV1}shipments/printMultipleBordereauOctal/${trackings}`, {
      responseType: 'blob',
    })
  }

  getWilayasFromRotationOctal() {
    return this.http.get(`${environment.apiV1}wilayas/getWilayasFromRotationOctal`)
  }

  getCommunesByWilayaIdOctal(idWilaya: number) {
    return this.http.get(`${environment.apiV1}communes/getCommunesByWilayaIdOctal/${idWilaya}`)
  }

  getWilayasFromRotationNoest() {
    return this.http.get(`${environment.apiV1}wilayas/getWilayasFromRotationNoest`)
  }


  getCommunesByWilayaIdNoest(idWilaya: number) {
    return this.http.get(`${environment.apiV1}communes/getCommunesByWilayaIdNoest/${idWilaya}`)
  }

  getBureauxByWilayaIdNoest(idWilaya: number) {
    return this.http.get(`${environment.apiV1}agences/getBureauxByWilayaIdNoest/${idWilaya}`)
  }




}
